import {
  Button,
  Chip,
  Flex,
  IconArrow,
  IconChevronDown,
  IconHamburger,
  Table,
  Text,
} from "@powerledger/ui-component-lib";
import currency from "currency.js";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column, Row } from "react-table";

import { EligibilityValueFormatter } from "@/app/components/eligibility-value-formatter";
import { HeaderText } from "@/app/components/page-header/header-text";
import { SeparatorLayout } from "@/app/components/separator-layout";
import { TableValueWrapper } from "@/app/components/table-value-wrapper";
import { UserMessage } from "@/app/components/user-message/user-message";
import { getTableTranslation } from "@/app/lib/get-translations-for-components";

import {
  AddDataProps,
  chipVariantProps,
  ChipVariantType,
  CounterOfferListProps,
  TableDataProps,
} from "./common-counter-offers.type";

const SubRows = ({ expandedData }: AddDataProps) => {
  const { t } = useTranslation();
  const expandedColumns = useMemo<Column<any>[]>(() => {
    return [
      {
        Header: "Counter Number",
        accessor: "counterId",
        Cell: ({ value }: { value: string }) => (
          <Flex>
            <Text>{value}</Text>
          </Flex>
        ),
      },
      {
        Header: "Counter On",
        accessor: "counterOn",
        Cell: ({ value }: { value: string }) => (
          <Flex>
            <Text>{value}</Text>
          </Flex>
        ),
      },
      {
        Header: "Counter Volume",
        accessor: "counteredVolume",
        Cell: ({ value }: { value: string }) => (
          <Flex>
            <Text>{value}</Text>
          </Flex>
        ),
      },
      {
        Header: "Counter Price",
        accessor: "counteredPrice",
        Cell: ({ value }: { value: number }) => (
          <Flex>
            <Text>
              {currency(value || 0, {
                separator: ",",
                fromCents: true,
              }).format()}
            </Text>
          </Flex>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }: { value: ChipVariantType }) => {
          const chipVariant: chipVariantProps = {
            REQUESTED: "info",
            REJECTED: "error",
            default: "primary",
          };
          const getVariant = chipVariant[value] ?? chipVariant["default"];
          return (
            <Flex>
              <Chip variant={getVariant} label={value} />
            </Flex>
          );
        },
      },
      {
        Header: t("Action"),
        id: "action",
        Cell: ({ row }: { row: Row<any> }) => <IconHamburger />,
      },
    ];
  }, [t]);
  return (
    <Table
      dataSource={expandedData}
      columns={expandedColumns}
      translation={getTableTranslation(t)}
      pagination={false}
    />
  );
};

export const CounterOffersListView = ({ tableData, counteredRECS, goBack }: CounterOfferListProps) => {
  const { t } = useTranslation();
  const counterListTableColumns = useMemo<Column<TableDataProps>[]>(() => {
    return [
      {
        id: "expandable",
        Cell: ({ row }: { row: Row<TableDataProps> }) => {
          const { isExpanded } = row;
          return (
            <Button
              sx={{
                background: "transparent",
                "&:enabled:hover, &:enabled:focus, &:enabled:active": {
                  background: "transparent",
                },
              }}
              aria-label={`expand row ${row.index + 1}`}
              onClick={(e) => {
                e.stopPropagation();
                row.toggleRowExpanded();
              }}
            >
              <IconChevronDown
                width={6}
                height={6}
                color="textDark"
                style={{
                  transform: isExpanded ? "rotate(180deg)" : "",
                }}
              />
            </Button>
          );
        },
      },
      {
        Header: "Offer Chain Number",
        accessor: "counterNumber",
        disableSortBy: true,
        Cell: ({ value }: { value: string }) => (
          <TableValueWrapper value={value} title="Counter Number" containerSx={{ minWidth: 80 }} />
        ),
      },
      {
        Header: "Created On",
        accessor: "receivedOn",
        Cell: ({ value }: { value: string }) => (
          <TableValueWrapper value={value} title="Received On" containerSx={{ minWidth: 80 }} />
        ),
      },
      {
        Header: t("Status"),
        accessor: "status",
        Cell: ({ row, value }: { row: Row<TableDataProps>; value: string }) => {
          const getVariant = () => {
            if (value === "ACCEPTED") {
              return "info";
            } else if (value === "REJECTED") {
              return "error";
            }
            return "primary";
          };
          return <Chip variant={getVariant()} label={value} />;
        },
      },
    ];
  }, [t]);
  return (
    <Flex as="main" sx={{ m: 4, flexDirection: "column", gap: 4 }}>
      <Button
        variant="text"
        sx={{ color: "accentLight", textDecoration: "underline", textUnderlineOffset: 2, alignSelf: "flex-start" }}
        onClick={goBack}
      >
        <IconArrow size={4} color="accentLight" />
        {t("Back")}
      </Button>
      <Flex sx={{ flexDirection: "inherit", gap: 2 }}>
        <HeaderText title="Counter Offer Lists" sx={{ width: "fit-content" }} />
        <UserMessage message="Here is the list of counter offers received for the following RECS. By default, the most recent offers are displayed first." />
      </Flex>
      {counteredRECS ? (
        <>
          <SeparatorLayout
            contentStyle={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: "14%",
            }}
            data={{
              Vintage: counteredRECS.vintages,
              Eligibility: (
                <EligibilityValueFormatter
                  certificationsAndEligibilities={counteredRECS.certificationsAndEligibilities}
                  position={counteredRECS.position}
                />
              ),
              Project: counteredRECS.projects,
              "Fuel Sources": counteredRECS.fuelSources,
              "Ask Volume": counteredRECS.currentVolume,
              "Ask Price": currency(counteredRECS.price.amount || 0, {
                separator: ",",
                fromCents: true,
              }).format(),
            }}
          />
          <Table
            columns={counterListTableColumns}
            dataSource={tableData}
            translation={getTableTranslation(t)}
            pageInfo={{
              pageSize: 20,
              totalPages: 1,
              currentPage: 1,
            }}
            manualPagination
            renderExpandedComponent={(data) => {
              const expandedData = data as unknown as any[];
              return <SubRows expandedData={expandedData} />;
            }}
            containerSx={{
              tbody: {
                "&:nth-of-type(n+2):before": {
                  borderColor: "shadow",
                  content: "''",
                  borderTopWidth: "0.5px",
                  borderTopStyle: "solid",
                  borderBottom: 0,
                  width: "100%",
                  position: "absolute",
                  top: 0,
                },
              },
              td: {
                borderTopLeftRadius: "0 !important",
                borderTopRightRadius: "0 !important",
              },
            }}
            initialState={{
              expandedRowKeys: 0,
            }}
          />
        </>
      ) : (
        <Text>{t("No any Selected Recs")}</Text>
      )}
    </Flex>
  );
};
