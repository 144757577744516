import { FormikHelpers } from "formik";
import { find } from "lodash";
import { useCallback, useMemo, useState } from "react";

import { useTransactionFee } from "@/app/hooks/use-transaction-fee";
import { CurrencyCode, OrderPosition, useTenantsQuery } from "@/app/types/generated/graphql";

import { CounterSteps, CreateCounterOfferHookProps, FormValues } from "./create-counter-offer-modal.types";

export const modalSubTitles = {
  [CounterSteps.COUNTER_FORM]: "Create a counter against this ask.",
  [CounterSteps.COUNTER_SUBMIT]: `By submitting this counter offer you are agreeing that the counterparty may accept it and if accepted the order will be executed immediately at the offered price.`,
  [CounterSteps.COUNTER_SUCCESS]: `Your offer has been received by the seller.`,
};

export const useCreateCounterOfferModal = ({
  visible,
  closeModal,
  counteredTableData,
  tenantID,
}: CreateCounterOfferHookProps) => {
  const [currentStep, setCurrentStep] = useState<CounterSteps>(CounterSteps.COUNTER_FORM);

  const { data: tenantsData } = useTenantsQuery();

  const { getAppliedFee } = useTransactionFee();

  const fee = getAppliedFee(OrderPosition.Bid);

  const modalSubTitle = modalSubTitles[currentStep];

  const handleStepChange = useCallback((step: CounterSteps) => {
    setCurrentStep(step);
  }, []);

  const [currencyCode, currencySymbol] = useMemo(() => {
    const tenant = find(tenantsData?.tenants, { id: tenantID });
    return [tenant?.localisation?.currencyCode as CurrencyCode, tenant?.localisation?.currencySymbol ?? "$"];
  }, [tenantID, tenantsData]);

  const onCounterConfirm = useCallback(
    async (values: FormValues, action: FormikHelpers<FormValues>) => {
      handleStepChange(CounterSteps.COUNTER_SUCCESS);
    },
    [handleStepChange],
  );

  return {
    visible,
    closeModal,
    counteredTableData,
    modalSubTitle,
    currentStep,
    handleStepChange,
    currencySymbol,
    onCounterConfirm,
    fee,
  };
};
