import { Box, Card, Flex, LegacyCheckbox, Table } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";

import { HeaderText } from "@/app/components/page-header/header-text";
import { RefreshIconButton } from "@/app/components/refresh-icon-button";
import { UserMessage } from "@/app/components/user-message/user-message";
import { getTableTranslation } from "@/app/lib/get-translations-for-components";

import { CounterOfferProps } from "./common-counter-offers.type";

export const CounterOffersView = ({
  showAllAttributes,
  setShowAllAttributes,
  tableData,
  titles,
  userMessage,
  loading,
  tableColumns,
}: CounterOfferProps) => {
  const { t } = useTranslation();

  return (
    <Flex as="main" sx={{ m: 4, flexDirection: "column", gap: 2 }}>
      <HeaderText title={titles} sx={{ width: "fit-content" }} />
      <UserMessage message={userMessage} />

      <Card variant="compact" sx={{ mt: 4 }}>
        <Flex
          sx={{
            background: "lightActive",
            p: 3,
            pb: 3,
            mb: -1,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            justifyContent: "space-between",
            borderWidth: "0.5px",
            borderStyle: "solid",
            borderColor: "shadow",
            zIndex: 1,
            position: "relative",
            borderBottomWidth: 0,
            alignItems: "center",
          }}
        >
          <LegacyCheckbox
            checked={showAllAttributes}
            sx={{
              color: "textDarker",
            }}
            label={t("Show All Attributes")}
            onChange={() => setShowAllAttributes((prev) => !prev)}
          />
          <RefreshIconButton loading={false} fetchData={() => {}} />
        </Flex>
        <Box
          sx={{
            table: {
              td: {
                whiteSpace: "unset",
              },
            },
          }}
        >
          <Table
            loading={loading}
            columns={tableColumns}
            dataSource={tableData}
            pageInfo={{
              pageSize: 20,
              totalPages: 1,
              currentPage: 1,
            }}
            initialState={
              showAllAttributes
                ? {}
                : {
                    hiddenColumns: ["commencementDate", "locations"],
                    expanded: true,
                  }
            }
            fetchData={() => {}}
            manualPagination
            showScrollArrows
            translation={getTableTranslation(t)}
            containerSx={{
              table: {
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderTop: 0,
              },
            }}
          />
        </Box>
      </Card>
    </Flex>
  );
};
