import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router";

import { listTableData } from "@/app/container/apollo-client/demo-data/demo-counter-offer";

import { MyCounterOffersListProps } from "./my-counter-offers-list.types";

export const useMyCounterOffersList = (): MyCounterOffersListProps => {
  const location = useLocation();
  const navigate = useNavigate();
  const counteredRECS = location.state?.selectedRecs;
  const goBack = useCallback(() => {
    navigate("/counter/my-offers");
  }, [navigate]);
  const tableData = listTableData;

  return { tableData, counteredRECS, goBack };
};
