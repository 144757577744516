import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { offersTableData } from "@/app/container/apollo-client/demo-data/demo-counter-offer";
import { useRecOptions } from "@/app/hooks/use-rec-options";

import { getCommonCounterOffersColumn } from "../common/counter-offers-column";
import { ReceivedCounterOffersProps } from "./received-counter-offers.types";

export const useReceivedCounterOffers = (): ReceivedCounterOffersProps => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showAllAttributes, setShowAllAttributes] = useState(false);
  const { recOptions, loading: recOptionsLoading } = useRecOptions();

  const titles = "Received Counter Offers";
  const userMessage = "Here is the list of sell orders that have received counter offers.";

  const loading = recOptionsLoading;

  const onSeeCounterOffers = useCallback(
    (recs: any) => {
      navigate("/counter/received-offers/offers", { state: { selectedRecs: recs } });
    },
    [navigate],
  );

  const tableColumns = useMemo(
    () => getCommonCounterOffersColumn(t, onSeeCounterOffers, recOptions),
    [t, onSeeCounterOffers, recOptions],
  );

  const tableData = offersTableData;

  return {
    showAllAttributes,
    setShowAllAttributes,
    recOptions,
    tableData,
    titles,
    userMessage,
    loading,
    onSeeCounterOffers,
    tableColumns,
  };
};
