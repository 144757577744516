import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { getCommonCounterOffersColumn } from "../common/counter-offers-column";
import { useReceivedCounterOffers } from "../received-counter-offers/use-received-counter-offers";
import { MyCounterOffersProps } from "./my-counter-offers.types";

export const useMyCounterOffers = (): MyCounterOffersProps => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const logic = useReceivedCounterOffers();
  const titles = "My Counter Offers";
  const userMessage = "Here is the list of sell orders, you have submitted counter offers for.";
  const onSeeCounterOffers = useCallback(
    (recs: any) => {
      navigate("/counter/my-offers/offers", { state: { selectedRecs: recs } });
    },
    [navigate],
  );
  const tableColumns = useMemo(
    () => getCommonCounterOffersColumn(t, onSeeCounterOffers, logic.recOptions),
    [t, onSeeCounterOffers, logic.recOptions],
  );
  return {
    ...logic,
    titles,
    userMessage,
    onSeeCounterOffers,
    tableColumns,
  };
};
